import { css } from "@emotion/core";

import iconDownPoj from '../images/icon_down_poj.gif';
import iconDownJM from '../images/icon_down_jm.png';
import iconDownJc from '../images/icon_down_jc.gif';
import iconPagePoj from '../images/icon_page_poj.png';
import iconPageJM from '../images/icon_page_jm.png';
import iconPageJc from '../images/icon_page_jc.png';
import iconLink from '../images/icon_link.png';
import iconPDF from '../images/icon_pdf.gif';
import iconLinkRightAirportBlue from '../images/icon_link_right_airport-blue.png';
import asideBot from '../images/aside_bot.gif';
import postPorts from '../images/post_ports.gif';
import postPort from '../images/post_port.gif';
import postMarinas from '../images/post_marinas.gif';
import postAirport from '../images/post_airport.gif';
import postCoastguard from '../images/post_coastguard.gif';

export const relatedLinkStyle =  css`
 color: #004469;
    padding: 0 10px 0 10px !important;
    margin: 0 0;
    padding: 0;
    float: left;
    
    h2.port {
        background: transparent url(${postPort}) no-repeat;
        padding: 5px 0 0 15px;
    }
    h2.ports {
        background: transparent url(${postPorts}) no-repeat;
        padding: 5px 0 0 15px;
                /*border-top:1px dashed rgb(154, 189, 203);*/  
            }
    h2.marinas {
        background: transparent url(${postMarinas}) no-repeat;
        padding: 5px 0 0 15px;
        /* margin-top: 32px; */
    }

    h2.coastguard {
        background: transparent url(${postCoastguard}) no-repeat;
        padding: 5px 0 0 15px;
        /* margin-top: 32px; */
    }

    ul.post a.port {
      background: transparent url(${postPort}) no-repeat -2px -2px;
      overflow: hidden;
      padding: 0.5em 0 0 1em;
      list-style-type: none;
    }

    ul.post a.airport {
        background: transparent url(${postAirport}) no-repeat -2px -2px;
        overflow: hidden;
        padding: 0.5em 0 0 1em;
        list-style-type: none;
    }

    ul.post a.marinas {
        background: transparent url(${postMarinas}) no-repeat -2px -2px;
        overflow: hidden;
        padding: 0.5em 0 0 1em;
        list-style-type: none;
    }

    ul.post a.coastguard {
        background: transparent url(${postCoastguard}) no-repeat -2px -2px;
        overflow: hidden;
        padding: 0.5em 0 0 1em;
        list-style-type: none;
    }

    
    .post {
      font-family: Tahoma,Verdana,Arial,Sans-serif;
      font-size: 1.0em;
    }

    ul.post li {
      border: none;
      margin: 0.5em 0;
      border-bottom: #fff 1px solid;
      color: #555;
    }

    ul.post li.fact {
      margin-top: 19px;
    }

    ul.post li a {
      padding: 0;
      line-height: 1.3em;
      text-decoration: none !important;
    }

    ul.post li a:hover {
      color: #000;
    }

    ul.post li a strong {
      color: #555;
    }

    ul.post li a:hover strong {
      color: #000;
    }

    #GalleryImages {
      img{
      width:80px;
      padding:0;
      display:inline-block;

    }
    .MuiButton-text,.MuiButton-label{
      padding:0 0 0 0;
      border:0;
      display:inline-block;
      float:left;
      width:80px;
    }
    .MuiButton-containedPrimary{
      background-color:transparent;
      padding:0;
      margin:0;
      border:0;
      display:inline-block;
      box-shadow:0 0 0 0;
    }
   
  }

  @media (min-width:820px){
    .navFurther, .pages{
      margin-bottom:10px;
    }
  }
 
@media (max-width:820px){
  width:100%;
  color: #004469;
  padding: 0 10px;

       h2 {
          background:#434343;
          line-height:50px;
          color:#fff;
          border-top:1px solid #fff;
          font-size:13px;
          padding-left:10px;
          margin-bottom:0px;
      }

        h2.ports {
          background: transparent url(${postPorts}) no-repeat;
          padding: 0px;
          padding-left:13px;
          /*border-top:1px dashed rgb(154, 189, 203);*/
          
      }
       h2.port {
          background: transparent url(${postPort}) no-repeat;
          padding: 0px;
          padding-left:13px;
          /*border-top:1px dashed rgb(154, 189, 203);*/
          
      }
        h2.marinas {
          background: transparent url(${postMarinas}) no-repeat;
          padding: 0px;
          padding-left:13px;
          /*border-top:1px dashed rgb(154, 189, 203);*/
          
      }
         h2.airport {
          background: transparent url(${postAirport}) no-repeat;
          padding: 0px;
          padding-left:13px;
          /*border-top:1px dashed rgb(154, 189, 203);*/
          
      }
         h2.coastguard {
          background: transparent url(${postCoastguard}) no-repeat;
          padding: 0px;
          padding-left:13px;
          /*border-top:1px dashed rgb(154, 189, 203);*/
          
      }
       .links,  .docs,  .navFurther,  .pages {
          background:#ededed;
          margin-left:0px;
      }
           .links li,  .docs li,  .navFurther li,  .pages li {
              border-bottom:none;
              border-top:1px solid #fff;
              background:#ededed;
              line-height:50px;
              vertical-align:middle;
              padding-left:13px !important;
          }

                .pages li a.port {
                  font-size:13px;
                  padding-left:28px;
                 
              }
  
       .banner {
          text-align:center;
      }

       .banner img {
          max-width:265px; 
      }

       #gallery {
          margin:5px 0px;
      }

            #mail2u {
                width:100%;
                clear:both;
                min-height:60px;
            }
              #mail2u h2 a {
                  border-top: 1px dashed rgb(154, 189, 203);
              }
  
  #tools {
      padding-top:10px;
  }

  #arrivals {
      display:none;
  }


}

@media (max-width:820px){
    width:100%;
    margin-top:10px;
}

 h2 {
      font-size: 1em;
      letter-spacing: 1px;
      margin-top: 0;
  }

   h2 .lc {
      text-transform: lowercase;
  }

   ul {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
  }

   ul li {
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;
      border-bottom: #9abdcb 1px dashed;
  }

   ul li a {
      display: block;
      line-height: 1.2em;
      padding: 0.7em 0 0.8em 0;
  }

   .navFurther li a,
   .pages li a,
   .links li a,
   .docs li a {
      padding-left: 28px;
  }
      
  .port  .navFurther li a,
  .gov  .navFurther li a {
      background: transparent url(${iconDownPoj}) no-repeat 0 5px;
  }
  
  .marinas  .navFurther li a {
      background: transparent url(${iconDownJM}) no-repeat 0 5px;
  }
  
  .coastguard  .navFurther li a {
      background: transparent url(${iconDownJc}) no-repeat 0 5px;
  }
  
   .pages li a {
      background: transparent url(${iconPagePoj}) no-repeat 0 5px;
  }
  
   .pages li a.marinas {
      background: transparent url(${iconPageJM}) no-repeat 0 5px;
  }
  
   .pages li a.coastguard {
      background: transparent url(${iconPageJc}) no-repeat 0 5px;
  }
  
   .links li a {
      background: transparent url(${iconLink}) no-repeat 0 5px;
  }
  
   .docs li a {
      background: transparent url(${iconPDF}) no-repeat 0 6px;
  }

   .navFurther li a {
    background: transparent url(${iconDownJM}) no-repeat 0 5px;
  }
  
   .pages li a.airport  {
    background: transparent url(${iconLinkRightAirportBlue}) no-repeat 0 5px;
  }

  @media (max-width:820px){
    .port  .navFurther li a,
    .gov  .navFurther li a
    {
      background: transparent url(${iconDownPoj}) no-repeat 0 10px;
    }

    .marinas  .navFurther li a {
      background: transparent url(${iconDownJM}) no-repeat 0 5px;
    }

    .coastguard  .navFurther li a {
      background: transparent url(${iconDownJc}) no-repeat 0 10px;
    }

     .pages li a.port {
      background: transparent url(${iconPagePoj}) no-repeat 0 5px;
    }

     .pages li a.marinas {
      background: transparent url(${iconPageJM}) no-repeat 0 5px;
    }

     .pages li a.coastguard {
      background: transparent url(${iconPageJc}) no-repeat 0 5px;
    }

     .links li a {
      background: transparent url(${iconLink}) no-repeat 0 5px;
    }
  }

   #gallery {
    margin: 1.5em 0;
  }

   #gallery img {
      padding-right: 0.5em;
      padding-top: 0.5em;
  }

   .banner {
    clear: both;
    margin: 0px 0px 10px;
  }

   .banner h4 {
    font-size: 0.7em;
    margin-top: 3em;
    letter-spacing: 1px;
    color: #999;
    font-weight: normal;
  }

  .banner img {
    width: 100%;
    height: auto;
  }

   .clear {
    height: 25px;
    background: transparent url(${asideBot}) no-repeat;
  }
`;